<template>
  <failureMessage :message="errMsg" :showSnackbar="showSnackbar" />
  <a :href="homePageUrl">
    <button
      style="
        padding-left: 20px;
        cursor: pointer;
        margin-top: 20px;
        border: none;
      "
      class="backbutton raffle-backbutton-beda"
    >
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.397623 7.0587C-0.132541 7.57933 -0.132541 8.42483 0.397623 8.94546L7.18372 15.6095C7.71388 16.1302 8.57486 16.1302 9.10503 15.6095C9.63519 15.0889 9.63519 14.2434 9.10503 13.7228L4.63045 9.33281H17.6428C18.3935 9.33281 19 8.73721 19 8C19 7.26279 18.3935 6.66719 17.6428 6.66719H4.63469L9.10079 2.27724C9.63095 1.75661 9.63095 0.911102 9.10079 0.390472C8.57062 -0.130157 7.70964 -0.130157 7.17947 0.390472L0.393381 7.05454L0.397623 7.0587Z"
          fill="#000616"
        />
      </svg></button
  ></a>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="color"
      :top="true"
      location="top"
    >
      <p class="prizeErrorMsg normal-snackbar mb-0">
        {{ message }}
      </p>
    </v-snackbar>
    <div>
      <div class="row customRaffleSecBg mb-raffleHeroSecHide sticky-raffle">
        <!-- Carousel Section Start -->
        <div
          class="col-8 p-0 mb-customRaffleStyles position-relative sticky-raffle-left"
          ref="raffleViewSection"
          v-if="templateRaffle?.hero?.is"
        >
          <div
            class="mb-CustomAbtWinningStyles position-relative"
            v-if="
              this.pageContent?.main?.page_contents?.pages[1]?.hero?.img
                .length > 1 && templateRaffle.hero.img.is
            "
          >
            <img
              :src="fetchImage(selectedImage(index))"
              class="customrafflePageWinningImg raffleHeroWidth imageFitStyle heightdefine custom-image-style"
              alt=""
              v-if="templateRaffle.hero.img.is"
            />
            <!--<div class="customSmallImgsPad raffleHeroWidth">
              <img v-if="templateRaffle.hero.img.is" v-for="(thumbnail, i) in this.pageContent?.main?.page_contents
                ?.pages[1]?.hero?.img" :key="i" :src="fetchImage(thumbnail)" alt=""
                class="customLdngwinningItemImgsList imageFitStyle" @click="selectImage(i, index)"
                :class="{ active: selectedIndex === i }" />
            </div>-->
            <div class="customSmallImgsPad raffleHeroWidth">
              <div
                v-for="(chunk, rowIndex) in chunkedImages"
                :key="rowIndex"
                class="row chunckdiv"
              >
                <img
                  v-for="(thumbnail, i) in chunk"
                  :key="i"
                  :src="fetchImage(thumbnail)"
                  alt=""
                  class="customLdngwinningItemImgsListnew imageFitStyle"
                  @click="selectImage(i + rowIndex * 4, index)"
                  :class="{ active: selectedIndex === i + rowIndex * 4 }"
                  style="width: 24%"
                />
              </div>
            </div>
          </div>
          <img
            :src="
              fetchImage(
                this.pageContent?.main?.page_contents?.pages[1]?.hero?.img[0]
              )
            "
            v-if="
              this.pageContent?.main?.page_contents?.pages[1]?.hero?.img
                .length === 1 && templateRaffle.hero.img.is
            "
            class="customrafflePageWinningImg raffleHeroWidth imageFitStyle"
            alt=""
          />
          <h4
            style="font-size: 18px; font-weight: 700; margin: 41px 0px 0px 60px"
            class="contentTitle"
            v-if="templateRaffle?.hero?.description?.is"
          >
            The Prize
          </h4>
          <p
            class="customRaffleDescTxt breakWord contentDescription"
            :style="{
              whiteSpace: 'pre-line',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Nunito Sans',
              marginLeft: '60px',
            }"
            v-if="templateRaffle?.hero?.description?.is"
          >
            {{
              pageContent?.main?.page_contents?.pages[1]?.hero?.prizeDetails
                ?.value
            }}
          </p>
          <!-- <h4
            style="font-size: 18px; font-weight: 700; margin: 43px 0px 0px 60px"
            class="contentTitle"
          >
            Prize details
          </h4>
          <p
            class="customRaffleDescTxt contentDescription"
            :style="{
              whiteSpace: 'pre-line',
              marginLeft: '60px',
            }"
          >
            {{
              pageContent?.main?.page_contents?.pages[1]?.hero?.prizeDetails
                ?.value
            }}
          </p> -->
        </div>
        <div
          class="col-4 p-0 sticky-raffle-right move-button scrollWithCnt"
          ref="raffleBuySection"
          :style="
            shouldBuySectionScroll
              ? {}
              : {
                  position: 'relative',
                  top: '-50px',
                  display: 'flex',
                  justifyContent: 'center',
                }
          "
        >
          <div>
            <div
              v-if="getAllData?.Items?.status === 0"
              class="customRaffleRightSecStyles scrollCardWidth"
              style="
                position: absolute;
                top: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <p
                class="raffleExpiry"
                :style="{ color: `${themeData?.secondaryColor}` }"
              >
                This Raffle is Inactive
              </p>
            </div>
            <div
              v-else-if="getAllData?.Items?.status === 2"
              class="customRaffleRightSecStyles scrollCardWidth"
              style="
                position: absolute;
                top: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <p
                class="raffleExpiry"
                :style="{ color: `${themeData.secondaryColor}` }"
              >
                This Raffle is Expired
              </p>
            </div>
            <div v-else>
              <div
                style="
                  width: 414px;
                  display: flex;
                  justify-content: center;
                  position: relative;
                  top: 0px;
                "
              >
                <div
                  :class="['customFooterTimer']"
                  :style="{
                    backgroundColor: `${themeData.primaryColor} !important`,
                    borderRadius: '13.846px',
                    width: '308px',
                    position: 'relative',
                    top: '0px',
                    zIndex: 2,
                    height: '64px',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                  }"
                  v-if="!winner?.is"
                >
                  <div>
                    <p
                      class="customFooterTimeLeft"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      {{ countdownTimers[0]?.days }}
                    </p>
                    <p
                      class="customFooterTimeTxt"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      DAYS
                    </p>
                  </div>
                  <div class="customFooterHrsTxt">
                    <p
                      class="customFooterTimeLeft"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      {{ countdownTimers[0]?.hours }}
                    </p>
                    <p
                      class="customFooterTimeTxt"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      HRS
                    </p>
                  </div>
                  <div class="customFooterMinsTxt">
                    <p
                      class="customFooterTimeLeft"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      {{ countdownTimers[0]?.minutes }}
                    </p>
                    <p
                      class="customFooterTimeTxt"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      MINS
                    </p>
                  </div>
                  <div>
                    <p
                      class="customFooterTimeLeft"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      {{ countdownTimers[0]?.secs }}
                    </p>
                    <p
                      class="customFooterTimeTxt"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                      }"
                    >
                      SECS
                    </p>
                  </div>
                </div>
                <div
                  :class="['customFooterTimer']"
                  :style="{
                    backgroundColor: `${themeData.primaryColor} !important`,
                    borderRadius: '13.846px',
                    width: '308px',
                    position: 'relative',
                    top: '0px',
                    zIndex: 2,
                    height: '64px',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                  }"
                  v-else
                >
                  <div
                    class="contentDescription"
                    style="
                      margin-top: 0px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <p
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                        marginBottom: '0px',
                        fontSize: '16px',
                        marginTop: ' -8px',
                        paddingTop: '0px',
                      }"
                    >
                      Raffle has ended
                    </p>
                    <p
                      class="customFooterTimeLeft"
                      :style="{
                        color:
                          themeData?.countdownTimer?.fontColor || '#FFFFFF',
                        fontSize: '28px',
                        fontWeight: 'bolder',
                      }"
                    >
                      00:00:00
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="customRaffleRightSecStyles scrollCardWidth customRaffleRightSecStylesTop"
                style="position: absolute; top: 35px"
              >
                <div class="customRaffleRightSecBg customRaffleRightSecBgTop">
                  <h1
                    class="raffleTitle"
                    :style="{ fontSize: '32px', fontWeight: '800' }"
                  >
                    {{ customraffleData1?.title }}
                  </h1>
                  <p
                    :style="{
                      height: '30px',
                      width: '160px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }"
                    class="contentPillButton"
                  >
                    £{{
                      Math.abs(
                        Number(customraffleData1?.entry_cost_gbp)
                      ).toFixed(2)
                    }}
                    per ticket
                  </p>
                </div>
                <div
                  class="customRaffleTktsSec raffleHeroWidth"
                  v-if="!winner?.is"
                >
                  <h3 class="contentDescription">
                    How many tickets would you like?
                  </h3>
                  <div
                    v-if="
                      this.soldTicketCount >= this.getRaffleData.total_entries
                    "
                    style="display: flex; justify-content: center"
                  >
                    <h4 class="heroDescription">😢 Tickets are soldout 😢</h4>
                  </div>
                  <template v-else>
                    <div
                      class="d-flex justify-content-center align-items-center mb-ticketSecBg"
                    >
                      <button
                        type="button"
                        class="btn customRaffleMinus"
                        @click="decreaseTicketCount"
                        key=""
                        :style="{
                          backgroundColor: `${themeData?.secondaryColor} !important`,
                          border: 'none',
                        }"
                      >
                        <i
                          class="fa-solid fa-minus"
                          :style="{ width: '17.5px', height: '20px' }"
                        ></i>
                      </button>
                      <p class="customRaffleTktCount">{{ ticketCount }}</p>
                      <button
                        type="button"
                        class="btn customRaffleMinus"
                        @click="increaseTicketCount"
                        :style="{
                          backgroundColor: `${themeData?.secondaryColor} !important`,
                          border: 'none',
                        }"
                      >
                        <i
                          class="fa-solid fa-plus"
                          :style="{ width: '17.5px', height: '20px' }"
                        ></i>
                      </button>
                    </div>
                    <div class="mb-customRaffBuyTkts">
                      <div
                        class="customRaffleBuyTktsBtnBg raffleHeroWidth customRaffleBuyTktsBtnBgTop"
                        :style="{ backgroundColor: themeData?.secondaryColor }"
                        v-on:click="openCustomDetailsModal()"
                      >
                        <p
                          :style="{
                            color: themeData?.raffleTitle?.fontColor,
                            margin: 0,
                            fontSize: '19px',
                            fontWeight: 800,
                          }"
                          class=""
                        >
                          Buy tickets
                        </p>
                        <p
                          v-if="
                            ticketCount && customraffleData1?.entry_cost_gbp
                          "
                          :style="{
                            color: themeData?.raffleTitle?.fontColor,
                            margin: 0,
                            fontSize: '18px',
                            fontWeight: 800,
                          }"
                        >
                          £{{
                            (
                              ticketCount * customraffleData1?.entry_cost_gbp
                            ).toFixed(2)
                          }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="customRaffleTktsSec raffleHeroWidth" v-else>
                  <h3
                    class="contentDescription"
                    :style="{
                      color:
                        `${themeData?.countdownTimer?.fontColor} !important` ||
                        '#FFFFFF',
                    }"
                  >
                    This raffle has now finished, your winner was "{{
                      winner?.name[0]
                    }}"
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Buy Section End -->
      </div>
      <div class="row customRaffleSecBg m-0 web-raffleHeroSecHide">
        <div
          class="col-8 p-0 mb-customRaffleStyles"
          v-if="templateRaffle?.hero?.is"
        >
          <div
            class="col-6 p-0 mb-CustomAbtWinningStyles position-relative"
            v-if="
              this.pageContent?.main?.page_contents?.pages[1]?.hero?.img
                .length > 1 && templateRaffle.hero.img.is
            "
          >
            <img
              :src="fetchImage(selectedImage(index))"
              class="customLdngPageWinningImg imageFitStyle customPageMainImg"
              alt=""
            />
            <div class="mb-customSmallImgsPad">
              <!-- {{ this.contentData[index]?.img }} -->
              <img
                v-for="(thumbnail, i) in this.pageContent?.main?.page_contents
                  ?.pages[1]?.hero?.img"
                :key="i"
                :src="fetchImage(thumbnail)"
                alt=""
                class="customLdngwinningItemImgsList imageFitStyle"
                @click="selectImage(i, index)"
                :class="{ active: selectedIndex === i }"
              />
            </div>
          </div>
          <img
            :src="
              fetchImage(
                this.pageContent?.main?.page_contents?.pages[1]?.hero?.img[0]
              )
            "
            v-if="
              this.pageContent?.main?.page_contents?.pages[1]?.hero?.img
                .length === 1 && templateRaffle.hero.img.is
            "
            class="customLdngPageWinningImg imageFitStyle customPageMainImg"
            alt=""
          />

          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0 16px;
              margin-top: 24px;
            "
          >
            <p
              :style="{
                height: '30px',
                backgroundColor: themeData?.secondaryColor,
                borderRadius: '15px',
                padding: '0px 10px',
                width: '180px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '24px',
              }"
              class="contentPillButton"
            >
              £{{
                Math.abs(Number(customraffleData1?.entry_cost_gbp)).toFixed(2)
              }}
              per ticket
            </p>
            <h1
              class="raffleTitle"
              :style="{
                fontSize: '32px',
                fontWeight: '800',
                marginBottom: '16px',
                textAlign: 'center',
              }"
              v-if="templateRaffle?.hero?.description?.is"
            >
              {{ customraffleData1?.title }}!
            </h1>
            <p
              class="customRaffleDescTxt breakWord contentDescription"
              :style="{
                whiteSpace: 'pre-line',
                marginBottom: '32px',
                fontFamily: 'Nunito Sans',
              }"
              v-if="templateRaffle?.hero?.description?.is"
            >
              {{
                pageContent?.main?.page_contents?.pages[1]?.hero?.description
                  ?.value
              }}
            </p>

            <!-- <p class="mb-time-until-draw" v-else>
              This raffle has now finished, your winner was
            </p> -->
          </div>
          <v-row
            :style="{
              backgroundColor: `${themeData.primaryColor} !important`,
              margin: '0px !important',
            }"
            class="mb-4 justify-content-center"
          >
            <p
              class="mb-time-until-draw"
              :style="{
                color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
              }"
              v-if="!winner?.is"
            >
              Time until draw:
            </p>
            <!-- <v-col cols="2"></v-col> -->
            <v-col cols="8" class="timer-main-top" v-if="!winner?.is">
              <div
                v-if="getAllData?.Items?.status === 0"
                :style="{
                  width: '100%',
                  height: '101px',
                  padding: '0px 10px 40px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                }"
              >
                <div>
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    DAYS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    00
                  </p>
                </div>
                <div class="customFooterHrsTxt">
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    HRS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    00
                  </p>
                </div>
                <div class="customFooterMinsTxt">
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    MINS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    00
                  </p>
                </div>
                <div>
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    SECS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    00
                  </p>
                </div>
              </div>
              <div
                v-else
                :style="{
                  width: '100%',
                  height: '101px',
                  padding: '0px 10px 40px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                }"
              >
                <div>
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    DAYS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    {{ countdownTimers[0].days }}
                  </p>
                </div>
                <div class="customFooterHrsTxt">
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    HRS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    {{ countdownTimers[0].hours }}
                  </p>
                </div>
                <div class="customFooterMinsTxt">
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    MINS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    {{ countdownTimers[0].minutes }}
                  </p>
                </div>
                <div>
                  <p
                    class="customFooterTimeTxt"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    SECS
                  </p>
                  <p
                    class="customFooterTimeLeft"
                    :style="{
                      color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                    }"
                  >
                    {{ countdownTimers[0].secs }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col
              cols="8"
              v-else
              style="display: flex; justify-content: center"
            >
              <div class="contentDescription raffleEndedText">
                <p
                  class="raffleEndedFont"
                  :style="{
                    color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                  }"
                >
                  Raffle has ended
                </p>
                <p
                  class="customFooterTimeLeft timerAfterRaffleEnded"
                  :style="{
                    color: themeData?.countdownTimer?.fontColor || '#FFFFFF',
                  }"
                >
                  00:00:00
                </p>
              </div>
            </v-col>
            <!-- <v-col cols="2"></v-col> -->
          </v-row>
          <div class="after-timer-top">
            <h4
              style="font-size: 18px; font-weight: 700; text-align: center"
              class="contentTitle"
            >
              The Prize
            </h4>
            <p
              class="customRaffleDescTxt contentDescription"
              :style="{ whiteSpace: 'pre-line' }"
            >
              <!-- {{ this.getPrizeData.summary }}  -->
              {{
                pageContent?.main?.page_contents?.pages[1]?.hero?.prizeDetails
                  ?.value
              }}
            </p>
          </div>

          <div class="customRaffleRightSecStyles"></div>
        </div>
        <div class="col-4 p-0"></div>
      </div>
      <!-- Content Part Start -->
      <div class="customRafflePageBg customRafflePageBgTop">
        <div class="customLandingBgColor">
          <div
            class="row customRaffleVideoSec mb-0 customRaffleVideoSecTop"
            v-if="isCard(0)"
          >
            <div
              class="col-6 mb-customRaffleStyles mb-cstmVideo customRaffleStylesTop"
            >
              <div
                class="col-8 p-0 mb-CustomAbtWinningStyles position-relative"
                v-if="
                  this.templateRaffle.contents[0].img.is &&
                  this.pageContent?.main?.page_contents?.pages[1]?.contents[0]
                    ?.img?.value?.length > 1
                "
              >
                <img
                  :src="fetchImage(selectedImageContent(index))"
                  class="customLdngPageContentImg imageFitStyle customPageMainImg"
                  alt=""
                  style="width: 548px; height: 339px"
                />
                <div class="mb-customSmallImgsPad">
                  <!-- {{ this.contentData[index]?.img }} -->
                  <img
                    v-for="(thumbnail, i) in this.pageContent?.main
                      ?.page_contents?.pages[1]?.contents[0]?.img?.value"
                    :key="i"
                    :src="fetchImage(thumbnail)"
                    alt=""
                    class="customLdngwinningItemImgsList imageFitStyle"
                    @click="selectImageContent(i, index)"
                    :class="{ active: selectedIndexContent === i }"
                  />
                </div>
              </div>
              <div
                v-if="
                  this.pageContent?.main?.page_contents?.pages[1]?.contents[0]
                    ?.img?.value?.length === 1
                "
                style="display: flex; justify-content: center"
              >
                <img
                  v-if="
                    this.templateRaffle.contents[0].img.is_image &&
                    this.templateRaffle.contents[0].img.is
                  "
                  :src="
                    fetchImage(
                      this.pageContent?.main?.page_contents?.pages[1]
                        ?.contents[0]?.img?.value[0]
                    )
                  "
                  class="customLdngPageContentImg imageWidth imageFitStyle customPageMainImg"
                  alt=""
                />
                <iframe
                  v-if="
                    this.templateRaffle.contents[0].img.is_video &&
                    this.templateRaffle.contents[0].img.is
                  "
                  class="customVideoStyles"
                  :src="
                    fetchImage(
                      this.pageContent?.main?.page_contents?.pages[1]
                        ?.contents[0]?.img?.value[0]
                    )
                  "
                >
                </iframe>
              </div>
            </div>
            <div class="col-5 p-0 mb-customRaffleStyles mobile-margin">
              <div class="customRaffleVideoSecTxt customRaffleVideoSecTxtTop">
                <p
                  class="customRaffleCharityTxt contentPillButton customRaffleCharityTxtTop"
                  :style="{ backgroundColor: this.themeData.secondaryColor }"
                  v-if="raffleContentTemplateDetails?.pillButton?.is"
                >
                  <i class="fa-solid fa-ribbon customRaffleRibbonIcon"></i
                  >{{
                    pageContent?.main?.page_contents?.pages[1]?.contents[0]
                      ?.pillButton?.value
                  }}
                </p>
                <h1
                  class="textAlignMob contentTitle"
                  v-if="raffleContentTemplateDetails?.title?.is"
                >
                  🤝
                  {{
                    pageContent?.main?.page_contents?.pages[1]?.contents[0]
                      ?.title?.value
                  }}
                </h1>
                <h6
                  style="margin-bottom: 24px"
                  class="textAlignMob breakWord contentDescription"
                  v-if="raffleContentTemplateDetails?.description?.is"
                >
                  {{
                    pageContent?.main?.page_contents?.pages[1]?.contents[0]
                      ?.description?.value
                  }}
                </h6>
                <div
                  v-if="
                    raffleContentTemplateDetails?.button?.is &&
                    pageContent?.main?.page_contents?.pages[1]?.contents[0]
                      ?.button?.value
                  "
                  class="buttonForMob"
                >
                  <button
                    type="button"
                    class="btn LearnMoreBtn"
                    :style="{
                      border: `1px solid ${themeData.secondaryColor}`,
                      color: themeData.secondaryColor,
                    }"
                    @click="go_to_page"
                  >
                    {{
                      pageContent?.main?.page_contents?.pages[1]?.contents[0]
                        ?.button?.value
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Content Part End -->
      <div
        class="row customRaffleSecBg mt-4 mb-0 web-raffleHeroSecHide"
        style="
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center; /* Center the text/content */
          z-index: 1000;
        "
      >
        <div
          v-if="getAllData?.Items?.status === 0"
          class="customRaffleRightSecStyles scrollCardWidth px-4"
          style="display: flex; align-items: center; justify-content: center"
        >
          <p
            class="raffleExpiry"
            :style="{ color: `${themeData.secondaryColor}` }"
          >
            This Raffle is Inactive
          </p>
        </div>
        <div
          v-else-if="getAllData?.Items?.status === 2"
          class="customRaffleRightSecStyles scrollCardWidth px-4"
          style="display: flex; align-items: center; justify-content: center"
        >
          <p
            class="raffleExpiry"
            :style="{ color: `${themeData.secondaryColor}` }"
          >
            This Raffle is Expired
          </p>
        </div>
        <div
          v-else
          :style="{
            backgroundColor: '#ffffff',
            width: '100%',
            boxShadow: `0 0 8px ${themeData.secondaryColor}`,
            borderTopLeftRadius: '50% 10%',
            borderTopRightRadius: '50% 10%',
          }"
        >
          <div class="p-0 mb-0 mb-customRaffleStyles" style="height: 205px">
            <div class="pb-4" style="padding: 25px" v-if="!winner?.is">
              <h3
                style="text-align: center; margin-bottom: 10px"
                class="contentTitle"
              >
                Select tickets
              </h3>
              <div
                v-if="this.soldTicketCount >= this.getRaffleData.total_entries"
                style="display: flex; justify-content: center"
              >
                <h4 class="heroDescription">😢 Tickets are soldout 😢</h4>
              </div>
              <template v-else>
                <div
                  class="d-flex justify-content-center align-items-center mb-ticketSecBg mb-4"
                >
                  <button
                    type="button"
                    class="btn customRaffleMinus"
                    @click="decreaseTicketCount"
                    key=""
                    :style="{
                      backgroundColor: `${themeData?.secondaryColor} !important`,
                      border: 'none',
                    }"
                  >
                    <i
                      class="fa-solid fa-minus"
                      :style="{ width: '17.5px', height: '20px' }"
                    ></i>
                  </button>
                  <p style="width: 165px" class="customRaffleTktCount">
                    {{ ticketCount }}
                  </p>
                  <button
                    type="button"
                    class="btn customRaffleMinus"
                    @click="increaseTicketCount"
                    :style="{
                      backgroundColor: `${themeData?.secondaryColor} !important`,
                      border: 'none',
                    }"
                  >
                    <i
                      class="fa-solid fa-plus"
                      :style="{ width: '17.5px', height: '20px' }"
                    >
                    </i>
                  </button>
                </div>
                <div class="mb-customRaffBuyTkts">
                  <div
                    :style="{
                      backgroundColor: themeData?.secondaryColor,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '10px',
                      borderRadius: '5px',
                    }"
                    v-on:click="openCustomDetailsModal()"
                  >
                    <p
                      :style="{
                        margin: 0,
                        fontSize: '18px',
                        fontWeight: 800,
                        color: themeData?.raffleTitle?.fontColor,
                      }"
                      class=""
                    >
                      Buy tickets
                    </p>
                    <p
                      v-if="ticketCount && customraffleData1?.entry_cost_gbp"
                      :style="{
                        margin: 0,
                        fontSize: '18px',
                        fontWeight: 800,
                        color: themeData?.raffleTitle?.fontColor,
                      }"
                      class=""
                    >
                      £{{
                        (
                          ticketCount * customraffleData1?.entry_cost_gbp
                        ).toFixed(2)
                      }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
            <div class="pb-4" v-else>
              <h3
                style="
                  text-align: center;
                  margin-bottom: 10px;
                  padding-top: 10%;
                "
                class="contentTitle"
                :style="{
                  color:
                    `${themeData?.countdownTimer?.fontColor} !important` ||
                    '#FFFFFF',
                }"
              >
                This raffle has now finished, your winner was "{{
                  winner?.name[0]
                }}"
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Custom Details Modal -->
    <div
      class="modal fade"
      id="customDetailsModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered customModalStyles">
        <div class="modal-content customDetailsModalBg">
          <div class="modal-body p-0">
            <div class="d-flex justify-content-end">
              <i
                class="fa-solid fa-xmark customModalCloseIcon"
                data-bs-dismiss="modal"
                @click="clear_fields"
              ></i>
            </div>
            <h1 class="customModalHeadingtxt customModalHeadingtxtTop">
              Please enter your details to continue
            </h1>
            <div class="customraffleModalMargin">
              <div
                class="row customTicketsEnteredBg align-items-center"
                :style="{
                  backgroundColor: `${themeData?.secondaryColor}1A`,
                  color: themeData?.secondaryColor,
                }"
              >
                <div class="col-7 mb-ticket-description-top">
                  <p class="contentDescription">
                    <strong
                      :style="{
                        color: themeData?.secondaryColor,
                      }"
                    >
                      Win a {{ customraffleData1?.title }}</strong
                    >
                  </p>
                </div>
                <div class="col-5 mb-ticket-count-top">
                  <p class="customEntryTicketCount contentDescription">
                    <strong
                      :style="{
                        color: themeData?.secondaryColor,
                        marginTop: '25px',
                      }"
                    >
                      <i class="fa-light fa-ticket customTicketIcon"></i>
                      <span>{{ ticketCount }}</span>
                      {{ ticketCount == 1 ? "x ticket" : "x tickets" }}
                    </strong>
                  </p>
                </div>
              </div>
              <div class="mb-0">
                <label for="customFirstName" class="form-label customLabelname"
                  >First name</label
                ><input
                  type="text"
                  placeholder="enter your first name"
                  class="form-control customInputFields"
                  id="customFirstName"
                  aria-describedby="emailHelp"
                  v-model="firstName"
                  @input="first_name"
                />
              </div>
              <div
                id="firstNameError"
                class="mb-4"
                v-if="firstNameError"
                :style="{ color: 'red', fontSize: '18px' }"
              >
                {{ firstNameError }}
              </div>
              <div class="mb-0">
                <label for="customFirstName" class="form-label customLabelname"
                  >Last name</label
                ><input
                  type="text"
                  placeholder="enter your last name"
                  class="form-control customInputFields"
                  id="customFirstName"
                  aria-describedby="emailHelp"
                  v-model="lastName"
                  @input="last_name"
                />
              </div>
              <div
                id="lastNameError"
                class="mb-4"
                v-if="lastNameError"
                :style="{ color: 'red', fontSize: '18px' }"
              >
                {{ lastNameError }}
              </div>
              <div class="mb-0">
                <label for="customFirstName" class="form-label customLabelname"
                  >Your email address</label
                ><input
                  type="text"
                  placeholder="enter your email address"
                  class="form-control customInputFields"
                  id="customFirstName"
                  aria-describedby="emailHelp"
                  v-model="emailId"
                  @input="email_id"
                />
                <div style="display: flex; flex-direction: column; gap: 20px">
                  <div style="display: flex; flex-direction: row; gap: 10px">
                    <input
                      type="checkbox"
                      id="charityUpdates"
                      v-model="charityOpt"
                      class="custom-checkbox"
                      :style="{ backgroundColor: themeData?.secondaryColor }"
                    />
                    <label for="charityUpdates">
                      I would like to receive updates and communication from
                      {{ raffleDetails?.organizationName }}
                    </label>
                  </div>

                  <div style="display: flex; flex-direction: row; gap: 10px">
                    <input
                      type="checkbox"
                      id="raiseHeroUpdates"
                      v-model="raiseHeroOpt"
                      class="custom-checkbox"
                    />
                    <label for="raiseHeroUpdates">
                      I would like to receive updates about other fundraisers
                      hosted by RaiseHero
                    </label>
                  </div>
                </div>
              </div>
              <div
                id="emailError"
                class="mb-4"
                v-if="emailError"
                :style="{ color: 'red', fontSize: '18px' }"
              >
                {{ emailError }}
              </div>
              <div class="mb-0">
                <button
                  type="button"
                  :style="{
                    backgroundColor: themeData?.secondaryColor,
                    marginTop: '15px',
                  }"
                  class="customDetailsContinueBtn contentButton"
                  :class="{ active: isFormFilled() }"
                  @click="fetch_custom_checkout"
                  :disabled="!isFormFilled() || isButtonClicked"
                >
                  Continue to Checkout
                </button>
              </div>
            </div>
            <StripeCheckout
              ref="checkoutRef"
              mode="subscription"
              :pk="publishableKey"
              :line-items="lineItems"
              :success-url="successUrl"
              :cancel-url="cancelUrl"
              @loading="(v) => (loading = v)"
            />
            <div v-if="isLoading" class="loader-container">
              <div class="loader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiURL } from "@/api/api";
import { bucketUrlForImages } from "@/api/api.js";
import { errorMsg } from "@/api/toastNotification";
import "@/assets/css/customRaffle.css";
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import charityList from "@/mixins/charity.json";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { isEmptyObject } from "jquery";
import moment from "moment";
import { mapGetters } from "vuex";
import failureMessage from "./failureMessage.vue";

export default {
  data: () => ({
    homePageUrl: "/",
    publishableKey: process.env.stripe_publishable_key,
    lineItems: [
      {
        price: "price_1Q8LOMG7hFQ5fNONKhUgXLUT",
        quantity: 1,
      },
    ],
    successUrl: `${window.location.origin}/paymentSuccess?session_id={CHECKOUT_SESSION_ID}`,
    cancelUrl: `${window.location.origin}/raffle`,
    showSnackbar: false,
    errMsg: "",
    templateThemeData: null,
    countdownTimers: [
      {
        days: "00",
        hours: "00",
        minutes: "00",
        secs: "00",
      },
    ],
    allCharitiesData: [],
    ticketCount: 1,
    charityList,
    snackbar: false,
    message: "Please select any one of the charities",
    timeout: 5000,
    color: "white",
    selectedCharity: null,
    selectedCharityId: null,
    customraffleData1: [],
    customraffleData: [],
    emailId: "",
    emailError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    charityRef: "",
    raffleId: "",
    formValidate: false,
    isButtonClicked: false,
    selectedIndex: 0,
    selectedIndexContent: 0,
    isLoading: false,
    thumbnails: [],
    shouldBuySectionScroll: true,
    charityOpt: false,
    raiseHeroOpt: false,
  }),
  components: { failureMessage, StripeCheckout },
  props: {
    themeData: {
      type: Object,
      required: true,
    },
    pageContent: {
      type: Object,
      required: true,
    },
    templateRaffle: {
      type: Object,
      required: true,
    },
    raffleDetails: {
      type: Object,
      required: true,
    },
    contentID: {
      type: Object,
      required: true,
    },
    winner: {
      type: Object,
      required: true,
    },
    soldTicketCount: {
      type: Number,
      required: true,
    },
    raffleContentTemplateDetails: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.storedCheckoutCart = JSON.parse(sessionStorage.getItem("PaymentData"));
    console.log({ storedCheckoutCart345: this.storedCheckoutCart });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.thumbnails =
      this.pageContent?.main?.page_contents?.pages[1]?.hero?.img;
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    this.charityRef = parts[parts.indexOf("customRafflePage") + 1];
    this.raffleCode = 21331;
    this.selectedCharityId = this.charityRef;

    this.$store.dispatch("fetch_custom_raffle").then(() => {
      this.customraffleData1 = this.getRaffleData;
    });

    this.startCountdown();

    if (!(this.charityRef && this.raffleCode)) {
      this.$router.push("/pageNotFound");
    } else {
      this.fetch_custom_raffle();
      const filteredCharity = this.charityList.filter((charity) => {
        return charity.id.toString() === this.charityRef;
      });
      if (filteredCharity.length > 0) {
        this.selectedCharity = filteredCharity[0];
      } else {
        this.selectedCharity = null;
        this.selectedCharityId = null;
      }
    }
  },
  computed: {
    ...mapGetters([
      "getAllData",
      "getRaffleData",
      "getPrizeData",
      "getTemplateTheme",
    ]),
    chunkedImages() {
      const allImages =
        this.pageContent?.main?.page_contents?.pages[1]?.hero?.img || [];
      const chunkSize = 4;
      const result = [];

      for (let i = 0; i < allImages.length; i += chunkSize) {
        result.push(allImages.slice(i, i + chunkSize));
      }

      // Add empty slots if images are fewer than 8
      if (result.length < 2) {
        result.push([]);
      }

      return result;
    },
  },
  methods: {
    isCard(index) {
      if (isEmptyObject(this.templateRaffle)) {
        return false;
      }

      return (
        this.templateRaffle.contents[index].button.is ||
        this.templateRaffle.contents[index].description.is ||
        this.templateRaffle.contents[index].img.is ||
        this.templateRaffle.contents[index].title.is ||
        this.templateRaffle.contents[index].pillButton.is
      );
    },
    selectedImage() {
      return this.pageContent?.main?.page_contents?.pages[1]?.hero?.img[
        this.selectedIndex
      ];
    },
    selectedImageContent() {
      return this.pageContent?.main?.page_contents?.pages[1]?.contents[0]?.img
        ?.value[this.selectedIndexContent];
    },
    fetchImage(filenames) {
      return `${bucketUrlForImages}/${this.contentID}/${filenames}`;
    },
    startCountdown() {
      const intervalId = setInterval(() => {
        console.log(this?.raffleDetails);

        this.countDownTime = this?.raffleDetails?.drawing_in;
        console.log(this.countDownTime);

        const inputDateStr = this.countDownTime?.replace(
          /(\d+)(st|nd|rd|th)/,
          "$1"
        );
        console.log("Input Date String:", inputDateStr);
        const parsedDate = new Date(inputDateStr);
        const now = moment();
        const endTime = moment(parsedDate);
        const duration = moment.duration(endTime.diff(now));
        if (
          duration.asMilliseconds() <= 0 ||
          this?.raffleDetails?.status === 5
        ) {
          clearInterval(intervalId);
          this.countdownTimers = [
            { days: "00", hours: "00", minutes: "00", secs: "00" },
          ];
          return;
        }

        const totalDays = Math.floor(duration.asDays());
        const days = String(totalDays).padStart(2, "0");
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");

        // Update the countdown timers
        this.countdownTimers = [{ days, hours, minutes, secs: seconds }];
      }, 1000);
    },
    selectImage(index) {
      this.selectedIndex = index ? index : 0;
    },
    selectImageContent(index) {
      this.selectedIndexContent = index ? index : 0;
    },
    prevImage() {
      this.selectedIndex =
        this.selectedIndex - 1 >= 0
          ? this.selectedIndex - 1
          : this.pageContent?.main?.page_contents?.pages[1]?.hero?.img?.length -
            1;
    },
    prevContentImage() {
      this.selectedIndexContent =
        this.selectedIndexContent - 1 >= 0
          ? this.selectedIndexContent - 1
          : this.pageContent?.main?.page_contents?.pages[1]?.contents[0]?.img
              ?.value?.length - 1;
    },
    nextImage() {
      this.selectedIndex =
        this.selectedIndex + 1 >
        this.pageContent?.main?.page_contents?.pages[1]?.hero?.img?.length - 1
          ? 0
          : this.selectedIndex + 1;
      this.selectedImage(this.selectedIndex);
    },
    nextContentImage() {
      this.selectedIndexContent =
        this.selectedIndexContent + 1 >
        this.pageContent?.main?.page_contents?.pages[1]?.contents[0]?.img?.value
          ?.length -
          1
          ? 0
          : this.selectedIndexContent + 1;
      this.selectedImageContent(this.selectedIndexContent);
    },
    go_to_page() {
      window.open(
        this.pageContent?.main?.page_contents?.pages[1]?.contents[0]?.button
          ?.link,
        "_blank"
      );
    },
    async fetch_custom_raffle() {},
    async fetch_custom_checkout() {
      gtag("event", "checkout", {
        event_category: "engagement",
        event_label: "Continue Checkout",
        value: 1,
      });

      this.isButtonClicked = true;
      this.isLoading = true;
      try {
        await fetch(apiURL.raffleCheckout, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            email: this.emailId.trim(),
            tickets: [
              {
                raffleId: this.raffleDetails.sort_id,
                numberOftickets: Number(this.ticketCount),
              },
            ],
            raffle_updates_opt: false,
            charityOpt: this.charityOpt,
            raiseHeroOpt: this.raiseHeroOpt,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log({ data });

            if (data?.wallet?.paymentData?.client_secret) {
              $("#customDetailsModal").modal("hide");
              this.customCheckout = data;
              sessionStorage.setItem(
                "SelectedCharityId",
                this.selectedCharityId
              );
              sessionStorage.setItem("RaffleUserName", this.firstName);
              sessionStorage.setItem("RaffleUserMailId", this.emailId);
              sessionStorage.setItem("UserTicketCount", this.ticketCount);
              sessionStorage.setItem(
                "client_secret",
                this.customCheckout.wallet.paymentData.client_secret
              );
              sessionStorage.setItem("PaymentData", JSON.stringify(data));
              if (this.customCheckout.wallet.paymentData.client_secret) {
                $("#customDetailsModal").modal("hide");
                this.clear_fields();
                this.isLoading = false;
                this.isButtonClicked = false;
                // this.$router.push("/payments");
                // this.$refs.checkoutRef.redirectToCheckout();
                window.location.href = data?.wallet?.url;
              } else {
                console.log("Something went wrong");
              }
              // }
              // else if (data?.wallet?.paymentData?.client_secret) {
              //   $("#customDetailsModal").modal("hide");
              //   this.customCheckout = data;
              //   sessionStorage.setItem(
              //     "SelectedCharityId",
              //     this.selectedCharityId
              //   );
              //   sessionStorage.setItem("RaffleUserName", this.firstName);
              //   sessionStorage.setItem("RaffleUserMailId", this.emailId);
              //   sessionStorage.setItem("UserTicketCount", this.ticketCount);

              //   sessionStorage.setItem("PaymentData", JSON.stringify(data));
              //   if (this.customCheckout.wallet.paymentData.client_secret) {
              //     $("#customDetailsModal").modal("hide");
              //     this.clear_fields();
              //     this.isLoading = false;
              //     this.isButtonClicked = false;
              //     // this.$router.push("/stripePaymentModel");
              //   }
              // else {
              //   console.log("Something went wrong");
              // }
            } else {
              $("#customDetailsModal").modal("hide");
              // this.showSnackbar = true;
              this.errMsg =
                data?.status === 404 || data?.status === 410
                  ? "Please Try Again"
                  : data?.message;
              errorMsg(this.errMsg);
              this.clear_fields();
              this.isLoading = false;
              this.isButtonClicked = false;
            }
          })
          .catch((error) => console.error(error));
      } catch (error) {
        this.snackbar = true;
        this.isButtonClicked = false;
        this.isLoading = false;
        if (this.customCheckout.message) {
          this.message = this.customCheckout.message;
        }
        this.message = error;
      }
    },

    clear_fields() {
      this.firstName = "";
      this.lastName = "";
      this.emailId = "";
      this.emailError = "";
      this.firstNameError = "";
      this.lastNameError = "";
      this.raiseHeroOpt = false;
      this.charityOpt = false;
    },
    confirmation_page() {
      this.$router.push({
        name: "rafflesConfirmationPage",
      });
    },
    increaseTicketCount() {
      console.log(
        "check",
        {
          ticketcount: this.ticketCount,
          total_entries_per_player: this.raffleDetails.total_entries_per_player,
          soldTicketCount: this.soldTicketCount,
          total_entries: this.raffleDetails.total_entries,
        },
        this.ticketCount < this.raffleDetails.total_entries_per_player &&
          this.ticketCount + this.soldTicketCount <
            this.raffleDetails.total_entries
      );
      if (this.raffleDetails.is_unlimited_entries) {
        if (
          this.ticketCount + this.soldTicketCount <
          this.raffleDetails.total_entries
        ) {
          this.ticketCount++;
        }
      } else {
        if (
          this.ticketCount < this.raffleDetails.total_entries_per_player &&
          this.ticketCount + this.soldTicketCount <
            this.raffleDetails.total_entries
        ) {
          this.ticketCount++;
        }
      }

      // if (
      //   this.ticketCount < this.raffleDetails.total_entries_per_player &&
      //   this.ticketCount + this.soldTicketCount <
      //     this.raffleDetails.total_entries
      // ) {
      //   this.ticketCount++;
      // }
    },
    decreaseTicketCount() {
      if (this.ticketCount > 1) {
        this.ticketCount--;
      }
    },
    selectCharity(charity) {
      this.selectedCharity = charity;
      this.selectedCharityId = charity.id;
    },
    openCustomDetailsModal() {
      gtag("event", "buy_ticket", {
        event_category: "engagement",
        event_label: "Buy Tickets Button",
        value: 1,
      });

      const modal = new bootstrap.Modal(
        document.getElementById("customDetailsModal")
      );
      modal.show();
    },
    showAlert() {
      this.snackbar = true;
    },
    formatDate(dateString) {
      return moment(dateString).format("Do MMMM YYYY [at] HH:mm");
    },
    first_name() {
      this.isFormDirty = true;

      if (
        this.firstName == null ||
        this.firstName == " " ||
        this.firstName.trim() === ""
      ) {
        this.firstNameError = "First name is required";
        return false;
      } else if (!/^[a-zA-Z0-9]+$/.test(this.firstName.trim())) {
        this.firstNameError =
          "First name should only contain alphanumeric characters";
        return false;
      } else if (this.firstName.trim().length > 40) {
        this.firstNameError = "First name must be less than 40 characters";
        return false;
      } else {
        this.firstNameError = "";
      }
      return true;
    },
    last_name() {
      this.isFormDirty = true;

      if (
        this.lastName == null ||
        this.lastName == " " ||
        this.lastName.trim() === ""
      ) {
        this.lastNameError = "Last name is required";
        return false;
      } else if (!/^[a-zA-Z0-9]+$/.test(this.lastName.trim())) {
        this.lastNameError =
          "Last name should only contain alphanumeric characters";
        return false;
      } else if (this.lastName.trim().length > 30) {
        this.lastNameError = "Last name must be less than 30 characters";
        return false;
      } else {
        this.lastNameError = "";
      }
      return true;
    },
    email_id() {
      this.isFormDirty = true;
      var emailRegex =
        /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

      if (!this.emailId.trim()) {
        this.emailError = "Email address is required";
        return false;
      } else if (!emailRegex.test(this.emailId.trim())) {
        this.emailError = "Invalid Email address";
        return false;
      } else {
        this.emailError = "";
      }
      return true;
    },
    isFormFilled() {
      return (
        this.firstName !== "" &&
        this.lastName !== "" &&
        this.emailId !== "" &&
        this.firstNameError === "" &&
        this.lastNameError === "" &&
        this.emailError === ""
      );
    },
    go_to_backpage() {
      const { origin, pathname } = window.location;
      const homeUrl = origin + "/" + pathname.split("/")[1] + "/";
      window.location.href = homeUrl;
      console.log("test", { test: window.location, origin, pathname });

      setTimeout(() => {
        if (window.location.href !== homeUrl) {
          location.reload();
        }
      }, 100);
    },
    navigateLearnMore(button) {
      if (button?.link) {
        window.open(button?.link, "_blank");
      }
    },
    sticky_buy_section_scrolling() {
      const firstComponent = this.$refs.raffleViewSection;
      const secondComponent = this.$refs.raffleBuySection;
      if (secondComponent && firstComponent) {
        const Yvalue = window.scrollY;
        const firstRect = firstComponent.getBoundingClientRect();
        const secondRect = secondComponent.getBoundingClientRect();
        const firstRectTop = firstRect.top + Yvalue;
        const firstRectBottom = firstRect.bottom + Yvalue;
        const scrollTill = window.innerHeight + Yvalue;
        if (
          firstRectTop <= window.scrollY &&
          window.scrollY <= firstRectBottom &&
          firstRectBottom >= scrollTill
        ) {
          secondComponent.style.position = "fixed";
          secondComponent.style.top = "0";
          secondComponent.style.transform = `translateY(${window.scrollY}px)`;
        } else if (firstRectBottom < scrollTill) {
          secondComponent.style.position = "relative";
        }
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.sticky_buy_section_scrolling);
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.scrollWithCnt {
  position: sticky;
  top: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sticky-raffle {
  position: relative;
}

.sticky-raffle-left {
  position: relative;
}

.sticky-raffle-right {
  right: 0;
  transition: 1s ease;
  height: 550px;
}

.customFooterTimeTxt {
  color: #fff;
  text-align: center;
  font-family: "Gilroy";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 4px;
}

.customFooterTimeLeft {
  color: #fff;
  text-align: center;
  font-family: "Gilroy";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 10px;
}

.raffleLearnBtnContent {
  width: 177px;
  height: 40px;
  border-radius: 34.286px;
  text-align: center;
  font-family: "Gilroy ExtraBold";
  font-size: 18.571px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.breakWord {
  word-break: break-word;
}

@media only screen and (max-width: 1150px) {
  .raffle-backbutton-beda {
    display: none;
  }

  .imageWidth {
    width: 95%;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1300px) {
  .raffleHeroWidth {
    width: 100% !important;
  }

  .scrollCardWidth {
    width: 414px;
  }
}

@media only screen and (max-width: 767px) {
  .raffle-backbutton-beda {
    display: none;
  }

  .backbutton {
    display: none;
  }

  .marginBottomMob {
    margin-bottom: 140px;
  }

  .textAlignMob {
    text-align: center;
  }

  .raffleExpiredMobile {
    display: block;
  }
  .customLdngwinningItemImgsList:nth-child(4n + 0) {
    margin-right: 0px !important;
  }
}

.customLdngwinningItemImgsListnew {
  height: 91.925px;
  border-radius: 14px;
  margin: 0px 5px 5px 0px !important;
  cursor: pointer;
  padding: 2px;
}

.customSmallImgsPad {
  width: 831.569px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 15px 0px 141.28px;
  gap: 10px;

  .chunckdiv {
    margin-left: 0px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gabarito";
}

button {
  font-family: "Gilroy";
}

.LearnMoreBtn {
  background-color: transparent;
  border: 1px solid #000000;
  width: fit-content;
  height: auto;
  min-height: 40px;
  border-radius: 34.29px;
  color: #000;
  text-align: center;
  font-family: "Gilroy";
  font-size: 18.571px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.raffleExpiry {
  font-size: 30px;
}

.raffleExpiredMobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile-margin {
    /* margin-bottom: 200px; */
  }
}

.chunckdiv {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
}

::v-deep(.v-label) {
  font-size: 13px !important;
  font-weight: normal;
  color: #000;
}

::v-deep(.v-input--selection-controls__input) {
  width: 18px !important;
  height: 18px !important;
}

.custom-checkbox {
  display: none; /* Hide the default checkbox */
}

.custom-checkbox + label {
  position: relative;
  padding-left: 35px; /* Space for the custom checkbox */
  cursor: pointer;
  font-size: 13px;
}

.custom-checkbox + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid black; /* Border color */
  border-radius: 4px; /* Optional: rounded corners */
  background-color: white; /* Background color */
}

.custom-checkbox:checked + label::before {
  border-color: var(--check-background); /* Change border color when checked */
  background-color: var(
    --check-background
  ); /* Change background color when checked */
}

.custom-checkbox:checked + label::after {
  content: "✔"; /* Add tick */
  position: absolute;
  left: 0px; /* Adjust to center the tick */
  top: 50%;
  transform: translateY(-50%);
  color: white !important; /* Tick color */
  font-size: 14px; /* Tick size */
  font-weight: lighter;
}

.custom-image-style {
  width: 831.569px;
  height: 422.89px !important;
  padding: 37.26px 28.28px 30px 141.28px;
  background: #fff;
  border-radius: 45px;
}

.raffleEndedText {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.raffleEndedFont {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: -8px;
  padding-top: 0px;
}

.timerAfterRaffleEnded {
  font-size: 28px;
  font-weight: bolder;
}
</style>
